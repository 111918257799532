<template>
  <main-nav></main-nav>
  <router-view class="mt-5" />
  <teleport to="body">
    <vue3-snackbar
      bottom
      right
      :duration="4000"
      success="rgba(0,0,0, 0.90)"
    ></vue3-snackbar>
  </teleport>
</template>
<script>
import MainNav from "@/views/layouts/MainNav.vue";

export default {
  setup() {
    return {};
  },
  components: {
    MainNav,
  },
};
</script>
<style lang="scss">
.vue3-snackbar {
  z-index: 99999 !important;
}

.vue3-snackbar-message-title,
.vue3-icon {
  // color: black !important;
}
</style>
