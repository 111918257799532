<template>
  <div class="d-flex justify-content-center align-items-center">
    <div><i class="far fa-spinner fa-spin fa-4x text-primary"></i></div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>