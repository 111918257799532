// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useStore } from "@/store";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIFa9D8sDhKONRBloLnRPu3sjWxEUiJEo",
  authDomain: "ibb-law-f94ab.firebaseapp.com",
  projectId: "ibb-law-f94ab",
  storageBucket: "ibb-law-f94ab.appspot.com",
  messagingSenderId: "114894209635",
  appId: "1:114894209635:web:66e01dd0a69df39c71ae56",
  measurementId: "G-8GFJJ0518C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  const store = useStore();
  if (user) {
    store.refreshUser();
  } else {
    store.signOut();
  }
});

export { app, analytics, storage, auth };
